#l-background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100vh;
}

#c-background__image {
    height: 100%;
    width: 100%;
    background: #2a002a;
}

#l-background__star--1 {
    position: absolute;
    top: 75px;
    left: 150px;
}


#l-background__star--2 {
    position: absolute;
    bottom: 20px;
    right: 200px;
}